<template>
  <div class="container" :style="{ 'margin-top': statusBarHeight }">
    <nav-header></nav-header>
    <main>
      <div class="personal-info">
        <img :src="avatar" alt="" />
        <span class="name">{{ name }}</span>
        <span class="gender">{{ genderInText }}</span>
      </div>
      <router-link :to="{ name: 'myProfile' }" v-slot="{ navigate }" custom>
        <div class="menu-item" @click="navigate">
          <span class="title">個人資料</span>
          <i class="icon-rightarrow"></i>
        </div>
      </router-link>
      <!-- PSMHJZ-72，移除历史病人相关的功能 -->
      <!-- <router-link
            :to="{ name: 'patientRecords' }"
            v-slot="{ navigate }"
            custom
        >
            <div class="menu-item" @click="navigate">
                <span class="title">病人記錄</span>
                <i class="icon-rightarrow"></i>
            </div>
        </router-link> -->
      <!-- <router-link :to="{ name: 'paymentData' }" v-slot="{ navigate }" custom>
            <div class="menu-item" @click="navigate">
                <span class="title">付款資料</span>
                <i class="icon-rightarrow"></i>
            </div>
        </router-link> -->
      <div class="help-center">
        <span class="title" @click.self="setShowCommonQuestions(true)">
          協助中心
        </span>
        <div class="freq-questions">
          <span class="title">常見問題</span>
          <div
            class="question"
            v-for="(item, index) in questionList.slice(0, 3)"
            :key="item.id"
            @click.stop="openCertainQuestion(index)"
          >
            <span>
              {{ item.question }}
            </span>
            <i class="icon-rightarrow"></i>
          </div>
        </div>
        <a class="get-help" href="https://wa.me/85260874942">聯繫客服</a>
      </div>
    </main>
  </div>
</template>

<script>
import { lastPersonals } from "@/api";
import navHeader from "../../components/navHeader.vue";
import { mapMutations, mapState } from "vuex";
export default {
  components: { navHeader },
  data: function () {
    return {
      avatar: "",
      name: "",
      gender: "",
      statusBarHeight: "0px",
    };
  },
  computed: {
    ...mapState({
      questionList: (state) => state.commonQuestions.questionList,
    }),
    genderInText() {
      return this.gender === 1 ? "男" : "女";
    },
  },
  methods: {
    ...mapMutations({
      setShowCommonQuestions: "commonQuestions/setShowCommonQuestions",
      openCertainQuestion: "commonQuestions/openCertainQuestion",
    }),
  },
  created: function () {
    // ios头部内容适配问题
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight + "px";
    }
    lastPersonals().then((res) => {
      this.avatar = res.data.avatar;
      this.name = res.data.name;
      this.gender = res.data.gender;
    });
  },
};
</script>

<style lang="scss" scoped>
main {
  padding-top: 6vh;
}
.container {
  background-color: $white;
  border-top: var(--safe-top) solid white;
  padding-bottom: var(--safe-bottom);
  min-height: 100vh;
}
.personal-info {
  padding: 16px;
  @include flexr-start-baseline;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    align-self: flex-end;
  }
  .name {
    font-size: 36px;
    color: $green-300;
    font-weight: $semi-bold;
    margin-right: 16px;
  }
  .gender {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    background-color: $green-300;
  }
}
.menu-item {
  margin-bottom: 16px;
  background-color: $green-300;
  color: #fff;
  padding: 16px;
  @include flexr-between-center;
  .title {
    font-size: 21px;
    font-weight: $semi-bold;
  }
}
.help-center {
  margin-bottom: 16px;
  background-color: $green-300;
  color: #fff;
  padding: 16px;
  @include flexc-start-stretch;
  .title {
    font-size: 24px;
    font-weight: $bold;
  }
  .freq-questions {
    @include flexc-start-stretch;
    margin-bottom: 8px;
    .title {
      font-size: 12px;
    }
    .question {
      width: 100%;
      font-size: 12px;
      color: rgba($color: #ffffff, $alpha: 0.64);
      @include flexr-between-center;
      span {
        display: inline-block;
        width: 80%;
        text-overflow: ellipsis;
        word-break: keep-all;
        overflow: hidden;
      }
    }
  }
  .get-help {
    text-align: center;
    background-color: white;
    color: $blue-200;
    padding: 8px;
    font-size: 14px;
  }
}
</style>